import * as _reactProperty2 from "react-property";

var _reactProperty = "default" in _reactProperty2 ? _reactProperty2.default : _reactProperty2;

import _utilities from "./utilities";
var exports = {};
var reactProperty = _reactProperty;
var utilities = _utilities;
/**
 * Converts HTML/SVG DOM attributes to React props.
 *
 * @param {object} [attributes={}] - HTML/SVG DOM attributes.
 * @returns - React props.
 */

exports = function attributesToProps(attributes) {
  attributes = attributes || {};
  var valueOnlyInputs = {
    reset: true,
    submit: true
  };
  var attributeName;
  var attributeNameLowerCased;
  var attributeValue;
  var propName;
  var propertyInfo;
  var props = {};
  var inputIsValueOnly = attributes.type && valueOnlyInputs[attributes.type];

  for (attributeName in attributes) {
    attributeValue = attributes[attributeName]; // ARIA (aria-*) or custom data (data-*) attribute

    if (reactProperty.isCustomAttribute(attributeName)) {
      props[attributeName] = attributeValue;
      continue;
    } // convert HTML/SVG attribute to React prop


    attributeNameLowerCased = attributeName.toLowerCase();
    propName = getPropName(attributeNameLowerCased);

    if (propName) {
      propertyInfo = reactProperty.getPropertyInfo(propName); // convert attribute to uncontrolled component prop (e.g., `value` to `defaultValue`)
      // https://reactjs.org/docs/uncontrolled-components.html

      if ((propName === "checked" || propName === "value") && !inputIsValueOnly) {
        propName = getPropName("default" + attributeNameLowerCased);
      }

      props[propName] = attributeValue;

      switch (propertyInfo && propertyInfo.type) {
        case reactProperty.BOOLEAN:
          props[propName] = true;
          break;

        case reactProperty.OVERLOADED_BOOLEAN:
          if (attributeValue === "") {
            props[propName] = true;
          }

          break;
      }

      continue;
    } // preserve custom attribute if React >=16


    if (utilities.PRESERVE_CUSTOM_ATTRIBUTES) {
      props[attributeName] = attributeValue;
    }
  } // transform inline style to object


  utilities.setStyleProp(attributes.style, props);
  return props;
};
/**
 * Gets prop name from lowercased attribute name.
 *
 * @param {string} attributeName - Lowercased attribute name.
 * @returns - Prop name.
 */


function getPropName(attributeName) {
  return reactProperty.possibleStandardNames[attributeName];
}

export default exports;