import _domToReact from "./lib/dom-to-react";
import _attributesToProps from "./lib/attributes-to-props";
import * as _htmlDomParser2 from "html-dom-parser";

var _htmlDomParser = "default" in _htmlDomParser2 ? _htmlDomParser2.default : _htmlDomParser2;

import * as _domhandler2 from "domhandler";

var _domhandler = "default" in _domhandler2 ? _domhandler2.default : _domhandler2;

var exports = {};
var domToReact = _domToReact;
var attributesToProps = _attributesToProps;
var htmlToDOM = _htmlDomParser; // support backwards compatibility for ES Module

htmlToDOM =
/* istanbul ignore next */
typeof htmlToDOM.default === "function" ? htmlToDOM.default : htmlToDOM;
var domParserOptions = {
  lowerCaseAttributeNames: false
};
/**
 * Converts HTML string to React elements.
 *
 * @param {string} html - HTML string.
 * @param {object} [options] - Parser options.
 * @param {object} [options.htmlparser2] - htmlparser2 options.
 * @param {object} [options.library] - Library for React, Preact, etc.
 * @param {Function} [options.replace] - Replace method.
 * @returns {JSX.Element|JSX.Element[]|string} - React element(s), empty array, or string.
 */

function HTMLReactParser(html, options) {
  if (typeof html !== "string") {
    throw new TypeError("First argument must be a string");
  }

  if (html === "") {
    return [];
  }

  options = options || {};
  return domToReact(htmlToDOM(html, options.htmlparser2 || domParserOptions), options);
}

HTMLReactParser.domToReact = domToReact;
HTMLReactParser.htmlToDOM = htmlToDOM;
HTMLReactParser.attributesToProps = attributesToProps;
HTMLReactParser.Element = _domhandler.Element; // support CommonJS and ES Modules

exports = HTMLReactParser;
exports.default = HTMLReactParser;
export default exports;